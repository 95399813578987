<template>
  <landing-scaffold>
    <section class="hero-text hero-text--dense overflow-hidden">
      <div class="container position-relative">
        <div class="columns align-center">
          <div class="column col-12 text-center">
            <div class="d-flex flex-column justify-center align-center">
              <h1 v-html="$t('hero.h1')" />
              <div class="hero-text__subtitle" v-html="$t('hero.subtitle')" />
              <img src="@/assets/landing/hero-text-mask.svg" alt="hero-mask" class="hero-text__mask position-absolute">
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="case-studies-container">
      <div class="container">
        <div class="columns case-study-list">
          <div class="col-4 col-md-12 case-study-container">
            <div
              @click="toggleVideo('https://www.youtube.com/embed/H7fCWBOOgPw')"
              class="case-study w-100 h-100 d-flex flex-column"
            >
              <div class="position-relative hover w-100">
                <img
                  src="@/assets/landing/case-studies/ikea/tim.png"
                  class="case-study__image w-100"
                  alt="Ikea tim"
                  style="object-position: top; object-fit: fill;"
                >
                <button class="play play--subtle absolute-center flex-center-center hover">
                  <play-icon :size="35" />
                </button>
              </div>
              <div class="d-flex flex-column case-study__content-container">
                <div>
                  <h5 class="mb-1" style="color: #0057AD;">
                    IKEA
                  </h5>
                  <!-- <img
                    src="@/assets/landing/customer_logos/ikea.svg"
                    class="case-study__logo"
                    style="height: 30px;"
                    alt="Ikea logo"
                  > -->
                  <div class="case-study__text">
                    {{ $t('use_cases.ikea') }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-4 col-md-12 case-study-container">
            <a
              class="case-study w-100 h-100 d-flex flex-column"
              :href="`/${$i18n.locale}/case-studies/lufthansa`"
            >
              <div class="position-relative hover w-100">
                <img
                  src="@/assets/landing/case-studies/lufthansa/hero.jpg"
                  class="case-study__image w-100"
                  alt="Lufthansa"
                >
              </div>
              <div class="d-flex flex-column case-study__content-container">
                <div>
                  <img
                    src="@/assets/landing/case-studies/lufthansa/logo.svg"
                    class="case-study__logo mb-0"
                    alt="Lufthansa logo"
                    style="height: 26px; object-fit: contain;"
                  >
                  <div class="case-study__text mt-1">
                    {{ $t('use_cases.lufthansa') }}
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-4 col-md-12 case-study-container">
            <a
              class="case-study w-100 h-100 d-flex flex-column"
              :href="`/${$i18n.locale}/case-studies/flixbus`"
            >
              <div class="position-relative hover w-100">
                <img
                  src="@/assets/landing/case-studies/flixbus/hero.jpg"
                  class="case-study__image w-100"
                  alt="DHL"
                  style="object-position: left;"
                >
              </div>
              <div class="d-flex flex-column case-study__content-container">
                <div>
                  <img
                    src="@/assets/landing/case-studies/flixbus/logo.svg"
                    class="case-study__logo mb-0"
                    alt="Flixbus logo"
                    style="height: 26px; object-fit: contain;"
                  >
                  <div class="case-study__text mt-1">
                    {{ $t('use_cases.flixbus') }}
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-4 col-md-12 case-study-container">
            <a
              class="case-study w-100 h-100 d-flex flex-column"
              :href="`/${$i18n.locale}/case-studies/dhl`"
            >
              <div class="position-relative hover w-100">
                <img
                  src="@/assets/landing/case-studies/dhl/hero.jpg"
                  class="case-study__image w-100"
                  alt="DHL"
                  style="object-fit: cover; object-position: bottom;"
                >
              </div>
              <div class="d-flex flex-column case-study__content-container">
                <div>
                  <img
                    src="@/assets/landing/case-studies/dhl/logo.svg"
                    class="case-study__logo mb-0"
                    alt="DHL logo"
                    style="height: 34px; object-fit: contain;"
                  >
                  <div class="case-study__text mt-1">
                    {{ $t('use_cases.dhl') }}
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-4 col-md-12 case-study-container">
            <a
              class="case-study w-100 h-100 d-flex flex-column"
              :href="`/${$i18n.locale}/case-studies/beiersdorf`"
            >
              <div class="position-relative hover w-100">
                <img
                  src="@/assets/landing/case-studies/beiersdorf/hero.jpg"
                  class="case-study__image w-100"
                  alt="Beiersdorf"
                >
                <div class="d-flex flex-column case-study__content-container">
                  <div>
                    <img
                      src="@/assets/landing/case-studies/beiersdorf/logo.svg"
                      class="case-study__logo mb-0"
                      alt="Beiersdorf logo"
                      style="height: 20px;"
                    >
                    <div class="case-study__text mt-1">
                      {{ $t('use_cases.beiersdorf') }}
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <a
            class="col-4 col-md-12 case-study-container"
            :href="`/${$i18n.locale}/case-studies/miro`"
          >
            <!-- @click="toggleVideo('https://www.youtube.com/embed/vRX4wLM_acU')" -->
            <div
              class="case-study w-100 h-100 d-flex flex-column"
            >
              <div class="position-relative hover w-100">
                <img
                  src="@/assets/landing/case-studies/miro/hero.jpg"
                  class="case-study__image w-100"
                  alt="Miro's Lisa"
                  style="object-position: right;"
                >
              </div>
              <div class="d-flex flex-column case-study__content-container">
                <div>
                  <img
                    src="@/assets/landing/customer_logos/miro.svg"
                    class="case-study__logo"
                    style="height: 30px;"
                    alt="Miro logo"
                  >
                  <div class="case-study__text">
                    {{ $t('use_cases.miro') }}
                  </div>
                </div>
              </div>
            </div>
          </a>
          <div class="col-4 col-md-12 case-study-container">
            <a
              class="case-study w-100 h-100 d-flex flex-column"
              :href="`/${$i18n.locale}/case-studies/teufel`"
            >
              <div class="position-relative hover w-100">
                <img
                  src="@/assets/landing/case-studies/teufel/thumbnail.png"
                  class="case-study__image w-100"
                  alt="Teufel"
                  style="object-fit: fill;"
                >
              </div>
              <div class="d-flex flex-column case-study__content-container">
                <div>
                  <img
                    src="@/assets/landing/case-studies/teufel/logo.svg"
                    class="case-study__logo mb-0"
                    alt="Teufel logo"
                    style="height: 30px;"
                  >
                  <div class="case-study__text mt-1">
                    {{ $t('use_cases.teufel') }}
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-4 col-md-12 case-study-container">
            <a
              class="case-study w-100 h-100 d-flex flex-column"
              :href="`/${$i18n.locale}/case-studies/rugby`"
            >
              <div class="position-relative hover w-100">
                <img
                  src="@/assets/landing/case-studies/rugby/thumbnail.jpg"
                  class="case-study__image w-100"
                  alt="England rugby"
                  style="object-fit: fill;"
                >
                <!-- <button class="play play--subtle absolute-center flex-center-center hover">
                  <play-icon :size="35" />
                </button> -->
              </div>
              <div class="d-flex flex-column case-study__content-container">
                <div>
                  <img
                    src="@/assets/landing/case-studies/rugby/logo.svg"
                    class="case-study__logo mb-0"
                    alt="England rugby logo"
                    style="height: 40px;"
                  >
                  <div class="case-study__text">
                    {{ $t('use_cases.rugby') }}
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-4 col-md-12 case-study-container">
            <div
              @click="toggleVideo('https://www.youtube.com/embed/4lLTJk7v1yM')"
              class="case-study w-100 h-100 d-flex flex-column"
            >
              <div class="position-relative hover w-100">
                <img
                  src="@/assets/landing/case-studies/swisscom/simon.png"
                  class="case-study__image w-100"
                  alt="Swisscom's Simon"
                  style="object-position: top;"
                >
                <button class="play play--subtle absolute-center flex-center-center hover">
                  <play-icon :size="35" />
                </button>
              </div>
              <div class="d-flex flex-column case-study__content-container">
                <div>
                  <img
                    src="@/assets/landing/customer_logos/swisscom.png"
                    class="case-study__logo"
                    style="height: 30px;"
                    alt="Swisscom logo"
                  >
                  <div class="case-study__text">
                    {{ $t('use_cases.swisscom') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 col-md-12 case-study-container">
            <a
              :href="`/${$i18n.locale}/case-studies/factworks`"
              class="case-study w-100 h-100 d-flex flex-column"
            >
              <img
                src="@/assets/landing/case-studies/factworks/hero.jpg"
                class="case-study__image w-100"
                alt="Factworks"
              >
              <div class="d-flex flex-column case-study__content-container">
                <div>
                  <img
                    src="@/assets/landing/customer_logos/factworks.svg"
                    class="case-study__logo"
                    alt="Factworks Logo"
                  >
                  <div class="case-study__text">
                    {{ $t('use_cases.factworks') }}
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-4 col-md-12 case-study-container">
            <a
              :href="`/${$i18n.locale}/case-studies/joyn`"
              class="case-study w-100 h-100 d-flex flex-column"
            >
              <img
                src="@/assets/landing/case-studies/joyn/hero.png"
                class="case-study__image w-100"
                alt="Joyn"
              >
              <div class="d-flex flex-column case-study__content-container">
                <div>
                  <img
                    src="@/assets/landing/customer_logos/joyn_dark.svg"
                    class="case-study__logo"
                    alt="Joyn"
                  >
                  <div class="case-study__text">
                    {{ $t('use_cases.joyn') }}
                  </div>
                </div>
              </div>
            </a>
          </div>
          <!-- <div class="col-4 col-md-12 case-study-container">
            <a
              :href="`/${$i18n.locale}/case-studies/ebay`"
              class="case-study w-100 h-100 d-flex flex-column"
            >
              <img
                src="@/assets/landing/case-studies/ebay/hero.jpg"
                class="case-study__image w-100"
                alt="Ebay"
              >
              <div class="d-flex flex-column case-study__content-container">
                <div>
                  <img
                    src="@/assets/landing/customer_logos/ebay.svg"
                    class="case-study__logo"
                    alt="ebay"
                  >
                  <div class="case-study__text">
                    Since 2020, Thomas and his team are using Caplena to analyze their large-scale CX and relationship-health monitoring studies.
                  </div>
                </div>
              </div>
            </a>
          </div> -->
          <div class="col-4 col-md-12 case-study-container">
            <a
              :href="`/${$i18n.locale}/case-studies/dr-schar`"
              class="case-study w-100 h-100 d-flex flex-column"
            >
              <img
                src="@/assets/landing/case-studies/dr-schar/hero.png"
                class="case-study__image w-100"
                alt="Dr. Schar"
              >
              <div class="d-flex flex-column case-study__content-container">
                <div>
                  <img
                    src="@/assets/landing/customer_logos/dr-schar_color.svg"
                    class="case-study__logo"
                    style="height: 20px;"
                    alt="Dr schar"
                  >
                  <div class="case-study__text">
                    {{ $t('use_cases.dr_schar') }}
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-4 col-md-12 case-study-container">
            <a
              :href="`/${$i18n.locale}/case-studies/link`"
              class="case-study w-100 h-100 d-flex flex-column"
            >
              <img
                src="@/assets/landing/case-studies/link/hero.jpg"
                class="case-study__image w-100"
                alt="Link Institut"
              >
              <div class="d-flex flex-column case-study__content-container">
                <div>
                  <img
                    src="@/assets/landing/customer_logos/link_dark.svg"
                    class="case-study__logo"
                    alt="Link Institut"
                  >
                  <div class="case-study__text">
                    {{ $t('use_cases.link') }}
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-4 col-md-12 case-study-container">
            <a
              :href="`/${$i18n.locale}/case-studies/cx-ai`"
              class="case-study w-100 h-100 d-flex flex-column"
            >
              <img
                src="@/assets/landing/case-studies/cx/hero.jpg"
                class="case-study__image w-100"
                alt="CX.AI"
              >
              <div class="d-flex flex-column case-study__content-container">
                <div>
                  <img
                    src="@/assets/landing/customer_logos/cx.png"
                    class="case-study__logo mb-2"
                    alt="CX.AI"
                    style="height: 30px;"
                  >
                  <div class="case-study__text">
                    {{ $t('use_cases.cx_ai') }}
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-4 col-md-12 case-study-container">
            <a
              :href="`/${$i18n.locale}/case-studies/iwd`"
              class="case-study w-100 h-100 d-flex flex-column"
            >
              <img
                src="@/assets/landing/case-studies/iwd/hero.jpg"
                class="case-study__image w-100"
                alt="IWD Market Research"
              >
              <div class="d-flex flex-column case-study__content-container">
                <div>
                  <img
                    src="@/assets/landing/customer_logos/iwd.svg"
                    class="case-study__logo"
                    alt="IWD Market Research"
                    style="height: 65px;"
                  >
                  <div class="case-study__text">
                    {{ $t('use_cases.iwd') }}
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-4 col-md-12 case-study-container">
            <a
              :href="`/${$i18n.locale}/case-studies/tegut`"
              class="case-study w-100 h-100 d-flex flex-column"
            >
              <img
                src="@/assets/landing/case-studies/tegut/hero.png"
                class="case-study__image w-100"
                alt="Tegut hero"
              >
              <div class="d-flex flex-column case-study__content-container">
                <div>
                  <img
                    src="@/assets/landing/customer_logos/tegut_color.svg"
                    class="case-study__logo"
                    alt="Tegut"
                    style="height: 50px;"
                  >
                  <div class="case-study__text">
                    {{ $t('use_cases.tegut') }}
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
    <trial />
    <transition name="fade">
      <div class="overlay" v-if="showVideo" @click="toggleVideo" />
    </transition>
    <transition name="fade">
      <div v-if="!!showVideo" id="video" class="modal-input modal-input--video" style="height: 55vh;">
        <iframe
          width="100%"
          height="100%"
          :src="`${showVideo}?autoplay=1`"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
        />
      </div>
    </transition>
  </landing-scaffold>
</template>

<script>
import LandingScaffold from '@/pages/LandingScaffold'
import Trial from '@/landing/Trial'
import PlayIcon from 'vue-material-design-icons/Play.vue'

export default {
  components: {
    LandingScaffold,
    Trial,
    PlayIcon
  },

  data () {
    return {
      showVideo: false
    }
  },
  computed: {},
  mounted () {},
  methods: {
    toggleVideo (link) {
      if (this.showVideo) this.showVideo = false
      else this.showVideo = link
    }
  }
}
</script>

<i18n locale='en' src='@/i18n/en/landing/CaseStudies.json' />
<i18n locale='de' src='@/i18n/de/landing/CaseStudies.json' />
<i18n locale='en' src='@/i18n/en/landing/Global.json' />
<i18n locale='de' src='@/i18n/de/landing/Global.json' />